@import url(https://fonts.googleapis.com/css?family=Montserrat:700|Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  overflow-x: hidden;

  background: white;
  font-family: 'Open Sans', sans-serif;
  animation: colorchange 14.1s;
  /* animation-name followed by duration in seconds*/
  /* you could also use milliseconds (ms) or something like 2.5s */
  -webkit-animation: colorchange 7.1s;
  /* Chrome and Safari */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: #121212;
  color: white;

}

.videoContainer {
  grid-template-columns: 40px 50px auto 50px 40px;
  grid-template-rows: 25% 100px auto;


}

.videoContainer iframe {
  margin-left: 1vw;
  padding-bottom: 2vh;
  width: 20vw;
  height: 20vh;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

}

@keyframes colorchange {
  0% {
    background: #121212;
  }

  /* 25% {
    background: #ac8404;
  }

  50% {
    background: #93BFC8;
  }

  75% {
    background: #04ac50;
  }

  100% {
    background: #121212;
  } */
}

@-webkit-keyframes colorchange

/* Safari and Chrome - necessary duplicate */
  {
  /* 0% {
    background: #121212;
  }

  25% {
    background: #ac8404;
  }

  50% {
    background: #93BFC8;
  } */

  /* 75%  {background: green;} */
  100% {
    background: #121212;
  }
}


.heading-background {

  position: fixed;
  opacity: .79;
  font-size: 20vw;
  /* left:-0.2em; */
  margin-left: 4vw;
  top: -0.5em;
  color: #ac8404;
  z-index: -2;

}
.threed{
  position: fixed;
  height: 100vh;
  width: 100vh;
  top:0;
  z-index: -1000;
  /* margin-left: -42vw; */
  margin-top: 12vw;
}
.threed button{
  position: fixed;
  height: 10vh;
  width: 60vw;
  /* :0; */
  /* z-index: 1; */
  margin-left: 10vw;
  margin-top: -70vh;
}


.header-title img {

  object-fit: cover;
  height: 100vh;
  width: 100vw;


}


nav {
  position: fixed;
  top: 18vh;
  left: 0;
  width: 10vw;
  font-size: 3em;
  opacity: .9;
  z-index: 100;
  font-weight: bold;
}

nav li {
  display: inline-block;
  cursor: pointer;
  color: red;
}

nav li .link {
  text-decoration: none;
  color: white;

  padding: .01vw;
  margin-right: 2vw;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

li .link:hover {
  opacity: 1;
  color: gray;
}

.active {

  opacity: 1;
}



.About {
  padding-top: 10vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20vw;
  padding-bottom: 6vh;
}

#about-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 8em;
  text-align: left;
  margin-top: -6vh;
}

.about-image-text {
  margin-top: -37vh;
  margin-left: 18vw;
  display: flex;
  flex-direction: column;
}

.about-image-text p {

  width: 70vw;
  text-align: justify;
  font-size: 1.5em;
  margin-left: -17vw;
}

#imgone {
  margin-top: 20vh;
  margin-left: -20vw;
  opacity: 1;
  width: 60vw;
  z-index: -1;
}

#imgtwo {
  display: none;

  width: 20em;

}

.work {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20vw;
  padding-bottom: 50vh;
  font-size: .7em;
}

.work .heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}

.work .work-content {
  display: flex;
  flex-direction: row;
}

#bandcamp {
  margin-left: 3vw;
  width: 25vw;
  height: 40vh;
}

.project img {
  margin-top: 3vh;
  width: 40em;
  height: 40em;
}

.project {
  font-size: .7em;
  margin-left: 1vw;
  text-align: center;
}

.project span {
  font-size: 2em;
  text-align: center;
}

.headingVideo {
  margin-top: 10vh;
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}

.contact h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
  margin: -.2vh;
}

.contact a {
  color: white;
  font-size: 2em;
  margin-left: 1vw;
  margin-top: 3vh;
}

.liveset-container iframe {
  width: 70vw;
  height: 70vh;
}

/* SMARTPHONES PORTRAIT */
@media only screen and (min-device-width: 320px) and (max-device-width : 480px) {
  #about-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 8em;
    text-align: left;
    margin-top: 5vh;
  }
.threed{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  position: fixed;
  height: 50vh;
  width: 97vw;
  bottom:0;
  /* z-index: -1; */
  margin-left: 3vw;
  margin-top: 65vh;
}
.threed img{
  /* transform: rotate(-90deg); */
    /* transform: rotate(-90deg); */
    position: fixed;
    height: 40vh;
    /* bottom: 0; */
    /* z-index: -1; */
    margin-left: 15vw;
    margin-top: 5vh;
}
  body {
    font-size: 6px;
  }

  header h1 {}

  .header-title {
    height: 100vh;
    opacity: 1;

  }
  .liveset-container iframe {
    width: 70vw;
    height: 70vh;
    margin-left: 1vw;
  }

  #about-text {
    margin-top: 50vh;
    margin-left: 0vw;
    font-size: 1em;
  }

  .about-image-text p {

    width: 98vw;
    font-size: 1.8em;
    padding-bottom: 30vh;
    padding-bottom: 30vh;
    padding-right: 2vw;

  }

  nav {
    position: fixed;
    font-size: small;
    top: 0vh;
    margin-left: 0vw;
    height: 5vh;
    opacity: 0.9;
    width: 100vw;
    background-color: #ac8404;
    
  }

  .videoContainer {
    display: flex;
    flex-direction: column;
    
  }

  nav li .link {

    padding: 2vw;
    opacity: 1;
    color: white;
    margin-left: 1vw;
  }

  .heading-background {
    /* display: none; */
    position: fixed;
    margin-top: 70vh;
    margin-left: 0vw;

  }

  .About {

    align-items: flex-start;
    margin-left: 0vw;
    /* top: 10em; */
    /* margin-top: 10vw; */
    width: 99vw;
    color: white;
    z-index: 1;
    height: 100vh;
    padding-top: 0vh;
    padding-bottom: 65vh;

  }

  #imgtwo {}

  #imgone {
    object-fit: cover;
    position: static;
    /* transform: scale(1.1); */
    margin-left: -18vw;
    margin-top: 70vw;
    width: 99vw;
    
    z-index: -1;
  }

  #bandcamp {
    width: 96vw;
    margin-left: 1vw;
  }

  .work {
    align-items: flex-start;
    left: 0;
    /* margin-right: 10vw; */
    padding-bottom: 100vh;
  }

  .work .project {
    /* margin-top: 1vh; */
    /* margin-left: 1.1vw; */
    display: grid;
    grid-template-rows: 2fr;
    font-size: .7em;
  }
  .work {
    margin-top: 16vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0vw;
    padding-bottom: 50vh;
    font-size: 1.3em;
  }
  
  .work .heading {
    margin-top: 10vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 8em;
    text-align: left;
    /* margin-left: -4vw; */
    margin-bottom: -0.2vh;
  }

  .work iframe {
    margin-top: .01vh;
    /* margin-left: 4vw; */
    width: 98vw;
    height: 25vh;
    opacity: .9;
    margin-bottom: 1vh;
  }

  .project img {
    width: 20em;
    height: 20em;
  }

  .contact h1 {
    align-items: flex-end;
    left: 0;
    margin-left: 0vw;
    margin-top: 10vh;
  }
  .contact img {
    width: 42vw;
  }
  .contact  {
    margin-bottom: 10vh;
  }

}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {


  body {}


  nav {
    display: none;
    font-size: 3em;
    width: 100vw;
    background-color: #ac8404;
    
  }

  .About {
    align-items: flex-start;
    top: 10em;
    color: white;
    z-index: 1;
    height: 100vh;
    padding-bottom: 60vh;
  }

  #bandcamp {
    width: 40vw;
  }

  .videoContainer {
    display: flex;
    flex-direction: column;
  }

  .work {
    align-items: flex-start;
    left: 0;
    margin-right: 10vw;
    padding-bottom: 80vh;
  }

  .work .project {
    margin-top: 3vh;
    display: grid;
    grid-template-rows: 1fr;
    font-size: .6em;
  }

  .work iframe {
    margin-top: 3vh;
    margin-left: -4vw;
    width: 70vw;
    height: 40vh;
    opacity: .9;
  }

  .project img {
    width: 20em;
    height: 20em;
  }

  .contact {
    display: none;
  }

  .contact h1 {
    font-size: 4em;
  }

  .contact a {
    margin-left: 1vh;
    font-size: 3.5em;
  }

}

/* Large screens ----------- */
